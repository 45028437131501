import React from 'react'
import './ResumeStyles.css'


const About = () => {
    return (
        <div className='training'>
            <div className='left'>
                <h1>COMING SOON</h1>
                <p></p>
            </div>
        </div>
    )
}

export default About
